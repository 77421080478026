import React from "react";
import { Link } from "react-router-dom";

const PortfolioList = ({ column, styevariation, data }) => {
  const baseUrl = "https://strapi.productdevelopment.services";
  return (
    <>
      {data.length ? (
        data.map((value, index) => (
          <div className={`${column}`} key={value.id}>
            <div className={`portfolio ${styevariation}`}>
              <div className="thumbnail-inner">
                <div
                  className={"thumbnail"}
                  style={{
                    backgroundImage: `url(${baseUrl}/${value.thumbnail.url})`,
                  }}
                ></div>
                <div className={`bg-blr-image`}></div>
              </div>
              <div className="content">
                <div className="inner">
                  <p>
                    {value.category === "mvp"
                      ? "MVP Development"
                      : value.category === "scraper"
                      ? "Scraper"
                      : value.category === "personal"
                      ? "Personal Project"
                      : ""}
                  </p>
                  <h4>
                    <a href={value.link}>{value.title}</a>
                  </h4>
                </div>
              </div>
              <Link
                className="link-overlay"
                to={"/projects/" + value.id}
              ></Link>
            </div>
          </div>
        ))
      ) : (
        <div className="portfolio"></div>
      )}
    </>
  );
};
export default PortfolioList;
