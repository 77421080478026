import React from "react";

const VimeoEmbed = ({ src, title }) => {
  return (
    <div
      style={{
        padding: "75% 0 0 0",
        position: "relative",
      }}
    >
      <iframe
        src={src}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        title={title}
      ></iframe>
    </div>
  );
};

export default VimeoEmbed;
