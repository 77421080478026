import React, { useEffect, useState } from "react";
import { FiChevronUp } from "react-icons/fi";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom";
import ScrollToTop from "react-scroll-up";
import PageHelmet from "../component/common/Helmet";
import Footer from "../component/footer/Footer";
import HeaderThree from "../component/header/HeaderThree";
import VimeoEmbed from "./VimeoEmbed";

const BlogDetails = ({}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [project, setProject] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    fetch("https://strapi.productdevelopment.services/projects/" + id)
      .then((res) => {
        if (res.ok) return res.json();
        throw res;
      })
      .then((data) => {
        setProject(data);
      });
  }, []);
  return (
    <React.Fragment>
      <PageHelmet pageTitle="Project Description" />
      <HeaderThree homeLink="/" logo="symbol-dark" color="color-black" />

      {/* Start Breadcrump Area */}
      {/* <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg_image--7"
        data-black-overlay="7"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-single-page-title text-center pt--100">
                <h2 className="title theme-gradient">
                  The Home of the Future <br /> Could Bebes
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* End Breadcrump Area */}

      {/* Start Blog Details */}
      <div className="rn-blog-details pt--110 pb--70 bg_color--5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner-wrapper">
                <div className="inner">
                  <ReactMarkdown
                    components={{
                      img: ({ node, ...props }) => {
                        if (props.alt.includes("Vimeo")) {
                          return (
                            <VimeoEmbed title={props.alt} src={props.src} />
                          );
                        }
                        return <img src={props.src} alt={props.alt} />;
                      },
                      blockquote: ({ node, ...props }) => {
                        return (
                          <blockquote
                            style={{
                              margin: 0,
                              padding: "0 1em",
                              borderLeft: "0.25em solid #8b949a",
                              fontStyle: "normal",
                            }}
                          >
                            {props.children}
                          </blockquote>
                        );
                      },
                    }}
                  >
                    {project ? project.content : ""}
                  </ReactMarkdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Blog Details */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <Footer />
    </React.Fragment>
  );
};
export default BlogDetails;
