import React, { Component } from "react";
import { FiCast, FiLayers, FiUsers, FiMonitor } from "react-icons/fi";

const ServiceList = [
  {
    icon: <FiMonitor />,
    title: "MVP Development",
    description:
      "Create the first iteration of your product, get market confirmation, and scale globally.",
  },
  {
    icon: <FiLayers />,
    title: "Data Scraping",
    description:
      "Source data from the internet and use it for your business operations.",
  },
  {
    icon: <FiUsers />,
    title: "Technical Consultation",
    description:
      "Get help from an expert and level up your technical knowledge.",
  },
];

class ServiceThree extends Component {
  render() {
    const { column } = this.props;
    const ServiceContent = ServiceList.slice(0, this.props.item);

    return (
      <React.Fragment>
        <div className="row">
          {ServiceContent.map((val, i) => (
            <div className={`${column}`} key={i}>
              <a href="/#contact">
                <div className="service service__style--2">
                  <div className="icon">{val.icon}</div>
                  <div className="content">
                    <h3 className="title">{val.title}</h3>
                    <p>{val.description}</p>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceThree;
