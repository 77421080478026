import React from "react";
import { FaGithub, FaLinkedinIn } from "react-icons/fa";
import { SiUpwork } from "react-icons/si";
import { BsFillXDiamondFill } from "react-icons/bs";

export const SocialShare = [
  {
    Social: <FaLinkedinIn />,
    link: "https://www.linkedin.com/in/capp-wiedenhoefer",
  },
  { Social: <FaGithub />, link: "https://github.com/cappww" },
  {
    Social: <SiUpwork />,
    link: "https://www.upwork.com/freelancers/~01c4cfca90cedb6c22",
  },
  {
    Social: <BsFillXDiamondFill />,
    link: "https://contra.com/cappdevelopment"
  }
];
