import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { FaStar } from "react-icons/fa";
import { FiChevronUp } from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import Slider from "react-slick";
import Helmet from "../component/common/Helmet";
import FooterTwo from "../component/footer/FooterTwo";
import HeaderThree from "../component/header/HeaderThree";
import ContactOne from "../elements/contact/ContactOne";
import PortfolioList from "../elements/portfolio/PortfolioList";
import TabTwo from "../elements/tab/TabTwo";
import { portfolioSlick2 } from "../page-demo/script";
import ServiceList from "../elements/service/ServiceList";

const SlideList = [
  {
    textPosition: "text-left",
    category: "Freelance Software Engineer",
    title:
      "Hi, I'm <span>Capp</span>. <br /> Let's make your product idea a reality.",
    description: "",
    buttonText: "",
    buttonLink: "",
  },
];

const PortfolioLanding = () => {
  let title = "About Me";
  let description =
    "I'm a freelancer who specializes in creating MVPs for tech startups.  From APIs to mobile apps, I can create almost anything an entrepreneur may need to get started with their new tech business. ";
  const [services, setServices] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    fetch("https://strapi.productdevelopment.services/services")
      .then((res) => {
        if (res.ok) return res.json();
        throw res;
      })
      .then((data) => setServices(data));
    fetch("https://strapi.productdevelopment.services/testimonials")
      .then((res) => {
        if (res.ok) return res.json();
        throw res;
      })
      .then((data) => setTestimonials(data));
    fetch("https://strapi.productdevelopment.services/projects")
      .then((res) => {
        if (res.ok) return res.json();
        throw res;
      })
      .then((data) => setProjects(data));
  }, []);

  return (
    <div>
      <Helmet pageTitle="Capp's Website" />
      <HeaderThree homeLink="/" logo="symbol-dark" color="color-black" />
      {/* Start Slider Area   */}
      <div id="home" className="fix">
        <div className="slider-wrapper">
          {/* Start Single Slide */}
          {SlideList.map((value, index) => (
            <div
              className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--34"
              key={index}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className={`inner ${value.textPosition}`}>
                      {value.category ? <span>{value.category}</span> : ""}
                      {value.title ? (
                        <h1
                          className="title"
                          dangerouslySetInnerHTML={{ __html: value.title }}
                        ></h1>
                      ) : (
                        ""
                      )}
                      {value.description ? (
                        <p className="description">{value.description}</p>
                      ) : (
                        ""
                      )}
                      {value.buttonText ? (
                        <div className="slide-btn">
                          <a
                            className="rn-button-style--2 btn-primary-color"
                            href={`${value.buttonLink}`}
                          >
                            {value.buttonText}
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* End Single Slide */}
        </div>
      </div>
      {/* End Slider Area   */}

      {/* Start About Area */}
      <div id="about" className="fix">
        <div className="about-area ptb--120  bg_color--1">
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src="/assets/images/about/about-10.jpg"
                      alt="About Images"
                    />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title">{title}</h2>
                      <p className="description">{description}</p>
                      <Button
                        style={{
                          backgroundColor: "#2f21b3",
                          borderColor: "#2f21b3",
                          fontFamily: "sans-serif",
                          fontWeight: "bold",
                          borderRadius: 15,
                          color: "white",
                        }}
                        target={"_blank"}
                        href={
                          "https://strapi.productdevelopment.services/uploads/Capp_s_Resume_013df988f6.pdf"
                        }
                      >
                        Download Resume
                      </Button>
                    </div>
                    <div className="row mt--30">
                      <TabTwo tabStyle="tab-style--1" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* End About Area */}

      {/* Start Services Area  */}
      <div id="services" className="fix">
        <div className="service-area creative-service-wrapper ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                  <h2 className="title">My Services</h2>
                  <p>
                    If you have questions regarding my services feel free to{" "}
                    <a href="/#contact">
                      <u>contact me</u>
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
            <div className="row creative-service">
              <div className="col-lg-12">
                <ServiceList
                  item="6"
                  column="col-lg-4 col-md-6 col-sm-6 col-12 text-left"
                />
              </div>
            </div>
            {/* 
          <div className="wrapper testimonial-sacousel-inner mb--55">
            <div className="testimonial-slick-activation mt--70 mt_sm--40">
              <Slider {...serviceSlick}>
                {services.map((value, index) => (
                  <iframe frameborder="0" height="462px" src={value.src} title="View this service on Contra" width="384px" />
                ))}
              </Slider>
            </div>
          </div> 
          */}
          </div>
        </div>
      </div>
      {/* End Services Area  */}

      {/* Start testimonial Area */}
      <div id="testimonials" className="fix">
        <div className="testimonial-area ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center service-style--3 mb--30">
                  <h2 className="title">Testimonials</h2>
                  <p>This is what my clients have said about my services.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="wrapper testimonial-sacousel-inner mb--55">
            <div className="testimonial-slick-activation mt--70 mt_sm--40">
              <Slider {...portfolioSlick2}>
                {testimonials.map((value, index) => (
                  <div className="testimonial" key={index}>
                    <div className="thumbnail">
                      <p>
                        {value.content.length > 300
                          ? value.content.slice(0, 300).concat('..."')
                          : value.content}
                      </p>
                      <div className="content">
                        <div className="stars">
                          {Array(value.rating).fill(<FaStar size={25} />)}
                        </div>
                        <p>- {value.author}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      {/* End testimonial Area */}

      {/* Start Portfolio Area */}
      <div id="portfolio" className="fix">
        <div className="portfolio-area ptb--120 bg_color--5">
          <div className="portfolio-sacousel-inner">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                    <h2 className="title">My Latest Projects</h2>
                    <p>Check out my portfolio of projects.</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <PortfolioList
                  styevariation="text-center mt--40"
                  column="col-lg-4 col-md-6 col-sm-6 col-12"
                  data={projects}
                />
              </div>
              <div className="row">
                <div className="col-lg-12">
                  {/* <div className="view-more-btn mt--60 mt_sm--30 text-center">
                    <a className="rn-button-style--2 btn-solid" href="/blog">
                      <span>View More</span>
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Portfolio Area */}

      {/* Start Blog Area */}
      {/* <div id="blog" className="fix">
        <div className="rn-blog-area ptb--120 bg_color--5 mb-dec--30">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="section-title text-center">
                  <h2>Latest News</h2>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration.
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt--60 mt_sm--40">
              {PostList.map((value, i) => (
                <div className="col-lg-4 col-md-6 col-12" key={i}>
                  <div className="blog blog-style--1">
                    <div className="thumbnail">
                      <a href="/blog-details">
                        <img
                          className="w-100"
                          src={`/assets/images/blog/blog-${value.images}.jpg`}
                          alt="Blog Images"
                        />
                      </a>
                    </div>
                    <div className="content">
                      <p className="blogtype">{value.category}</p>
                      <h4 className="title">
                        <a href="/blog-details">{value.title}</a>
                      </h4>
                      <div className="blog-btn">
                        <a className="rn-btn text-white" href="/blog-details">
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div> */}
      {/* End Blog Area */}

      {/* Start Contact Area */}
      <div id="contact" className="fix">
        <div className="rn-contact-area ptb--120 bg_color--1">
          <ContactOne />
        </div>
      </div>
      {/* End Contact Area */}

      <FooterTwo />
      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </div>
  );
};

export default PortfolioLanding;
